import { useCallback, useEffect, useState } from "react";
import { ContentWrapper, StaticMap } from "../../components";
import { ApiRequest } from "../../helpers";
import { API_END_POINTS } from "../../config";
import { useLocation } from 'react-router'
import L from 'leaflet'
import PolylineUtils from '@mapbox/polyline'

import gpsMarker from '../../assets/svgIcon/gpsMarker.svg';
import mapMarker from '../../assets/svgIcon/mapMarker.svg';

const gpsMarkerIcon = L.icon({
    iconUrl: gpsMarker,
    iconSize: [8, 8],
    // iconAnchor: [15, 27],
    popupAnchor: [0, -10]
});

const stopMarkerIcon = L.icon({
    iconUrl: mapMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 27],
    popupAnchor: [0, -10]
});

const GPSViewPage = () => {
    const [markers, setMarkers] = useState([]);
    const [checkpoints, setCheckpoints] = useState(null);
    const [stops, setStops] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tripId = queryParams.get('tripId');
    const routeId = queryParams.get('routeId');
    const direction = queryParams.get('direction');

    useEffect(() => {
        handleGetLogs();
        handleGetStops();
    }, []);

    const handleGetLogs = useCallback(async () => {
        if (tripId) {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: API_END_POINTS.GET_TRIP_LOG,
                params: {
                    tripId
                }
            })

            const m = [];
            data.forEach(d => {
                m.push([d.latitude, d.longitude]);
            });

            setMarkers(m);
        }
    }, [tripId])

    const handleGetStops = useCallback(() => {
        if (routeId) {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ROUTE_GET}/${routeId}`
            }).then((route) => {
                const newdata = [];

                const decodedPolyline = PolylineUtils.decode(route.apadPolygon)
                setCheckpoints(decodedPolyline);

                route.stops.forEach(({ latitude, longitude, name, directionId }) => {
                    if (Number(directionId) === Number(direction))
                        newdata.push([latitude, longitude, name])
                })

                setStops(newdata)
            }).catch(e => {
                console.error(e);
            });
        }
    }, [routeId])

    return (
        <ContentWrapper >
            <h2 className='mb-3'>GPS Data View</h2>
            <h3 className='mb-3'>Trip ID: {tripId}</h3>
            <StaticMap 
                height='80vh' 
                gpsMarkers={markers} 
                markers={stops} 
                icon={stopMarkerIcon} 
                gpsIcon={gpsMarkerIcon} 
                checkpointMarkers={checkpoints} 
                scrollWheelZoom={true} />
        </ContentWrapper>
    )
}

export default GPSViewPage;