import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }
        
        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        onClearQuery()
    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='From Date' >
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM-DD')} onChange={(e) => setFromDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control id='date-to' min={moment(selectFromDate).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 23:59:59` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                            </Row>
                            <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="Weekend / Weekday" >
                                <Form.Control value={weekendWeekday} onChange={(e) => setWeekendWeekday(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Weekend</option>
                                    <option>Weekday</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Paid By' >
                                <Form.Control value={paidBy} onChange={(e) => setPaidBy(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Cash</option>
                                    <option>Cashless</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const TripCollectionPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)

    const handleGetTransactionHistory = async () => {
        setLoading(true)
        try {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.TRIP_COLLECTION}`,
                params: {
                    timestamp: new Date()
                }
            })
            // 
            setTransaction(data)
            const sortedData = _.orderBy(data, [({ startedAt }) => new Date(startedAt)], ['desc'])
            

            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            const groupedData = _.groupBy(addedLocalTime, 'localDate')
             
            setTripCollection(groupedData)
        } catch (error) { }
        finally {
            setLoading(false)
        }
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then((data) => {
            setRoutesArr(data)
        }).catch(e => { })
    }

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    useEffect(() => {
        handleGetTransactionHistory()
        handleGetAllRoute()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Trip Start Date',
            accessor: 'localTimeGroup_',
            // disableFilters: true
        },
        {
            Header: 'Total Trip Count',
            accessor: 'totalTripCount_',
            // disableFilters: true
        },
        {
            Header: 'Total Driver',
            accessor: 'totalUniqueDriverCount_',
            // disableFilters: true
        },
        {
            Header: 'Total Vehicle',
            accessor: 'totalUniqueVehicleCount_',
            // disableFilters: true
        },
        {
            Header: 'Total Collection (MYR)',
            accessor: 'totalAmount_',
            // disableFilters: true
        },
        {
            Header: 'Total Transaction Count',
            accessor: 'totalTransaction_',
            // disableFilters: true
        },
        {
            Header: 'Total Pax',
            accessor: 'totalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Cash (MYR)',
            accessor: 'cashTotalAmount_',
            // disableFilters: true
        },
        {
            Header: 'Cash Pax',
            accessor: 'cashTotalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Cashless (MYR)',
            accessor: 'cashlessTotalAmount_',
            // disableFilters: true
        },
        {
            Header: 'Cashless Pax',
            accessor: 'cashlessTotalRidership_',
            // disableFilters: true
        },
    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = filteredTripCollection || tripCollection
        if (!mainData) return []

        Object.entries(mainData).forEach(([localTimeGroup, trxs]) => {
            // 
            const accumulativeTrip = {
                'datetime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                'checkoutTime_': moment(trxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss'),
                'uniqueTrip_': new Set(),
                'totalTripCount_': 0,
                'uniqueDriver_': new Set(),
                'totalUniqueDriverCount_': 0,
                'uniqueVehicle_': new Set(),
                'totalUniqueVehicleCount_': 0,
                'uniqueJourney_': new Set(),
                'totalTransaction_': 0,
                'totalAmount_': 0,
                'noOfAdult': 0,
                'noOfChild': 0,
                'noOfSenior': 0,
                'noOfOku': 0,
                'noOfForeignAdult': 0,
                'noOfForeignChild': 0,
                'totalRidership_': 0,
                'cashTotalAmount_': 0,
                'cashTotalRidership_': 0,
                'cashlessTotalAmount_': 0,
                'cashlessTotalRidership_': 0,
            }
            trxs.map((row) => {
                const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                accumulativeTrip['uniqueDriver_'].add(row.driverName)
                accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                row.tripId && accumulativeTrip['uniqueTrip_'].add(row.tripId)
                row.journeyId && accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                accumulativeTrip['totalAmount_'] += +row.amount
                accumulativeTrip['noOfAdult'] += +row.noOfAdult
                accumulativeTrip['noOfChild'] += +row.noOfChildcashTotalRidership_
                accumulativeTrip['noOfSenior'] += +row.noOfSenior
                accumulativeTrip['noOfOku'] += +row.noOfOku
                accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                accumulativeTrip['totalRidership_'] += totalPax

                accumulativeTrip['cashTotalAmount_'] += !row.userId && row.paymentType == 'OFFLINE' ? +row.amount : 0
                accumulativeTrip['cashTotalRidership_'] += !row.userId && row.paymentType == 'OFFLINE' ? totalPax : 0

                accumulativeTrip['cashlessTotalAmount_'] += row.userId || row.paymentType != 'OFFLINE' ? +row.amount : 0
                accumulativeTrip['cashlessTotalRidership_'] += row.userId || row.paymentType != 'OFFLINE' ? totalPax : 0
            })

            accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
            accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
            accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
            accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
            accumulativeTrip['localTimeGroup_'] = localTimeGroup
            accumulativeTrip['trxs'] = trxs
            

            //format amount
            accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
            accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
            accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

            returnData.push(accumulativeTrip)
        })
        return returnData
    }, [tripCollection, filteredTripCollection])

    const handleFilterTransactionData = useCallback(({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        if (!transaction) return []
        const filtered = transaction.filter(({ createdAt,startedAt, routeShortName, driverName, vehicleRegistrationNumber, userId }) => {
            let returnVal = true
            if (amPm !== 'All') {
                returnVal = String(moment(startedAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
                if (!returnVal) return false
            }

            if (weekendWeekday !== 'All') {
                const isWeekendWeekday = WEEKEND_DAY_NUM.includes(new Date(startedAt).getDay()) ? 'Weekend' : 'Weekday'
                returnVal = isWeekendWeekday === weekendWeekday
                if (!returnVal) return false
            }

            if (selectFromDate) {
                returnVal = new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                returnVal = new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false
            }

            if (vehicle) {
                returnVal = vehicleRegistrationNumber === vehicle
                if (!returnVal) return false
            }

            if (driver) {
                returnVal = driverName === driver
                if (!returnVal) return false
            }

            if (paidBy !== 'All') {
                returnVal = userId ? 'cashless' : 'cash' === String(paidBy).toLowerCase()
                if (!returnVal) return false
            }

            return true
        })

        const sortedData = _.orderBy(filtered, [({ startedAt }) => new Date(startedAt)], ['desc'])
        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        const groupedData = _.groupBy(addedLocalTime, 'localDate')
        setFilteredTripCollection(groupedData)
    }, [transaction])

    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
    }, [])

    const generateExportCsvData = useCallback(() => {
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')

        const dateGenerated = `Generated At:, ${new Date().toString()}\r\n`
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}\r\n\n`
        // const header = "Trip Start, Trip End, Trip ID, Driver Name, Vehicle ID, Route ID, Cash (MYR), Cash Pax, Adult Cash (MYR), Adult Pax, Child Cash (MYR), Child Pax, Senior Cash (MYR), Senior Pax, Disabled Cash (MYR), Disabled Pax, Foreigner - Adult Cash (MYR), Foreigner - Adult Pax, Foreigner - Child Cash (MYR), Foreigner - Child Pax, Cashless (MYR), Cashless Pax, Adult Cashless (MYR), Adult Pax, Child Cashless (MYR), Child Pax, Senior Cashless (MYR), Senior Pax, Disabled Cashless (MYR), Disabled Pax, Foreigner - Adult Cashless (MYR), Foreigner - Adult Pax, Foreigner - Child Cashless (MYR), Foreigner - Child Pax, Total Transaction Count\r\n"
        const header = "Trip Start, Trip End, Trip ID, Serial Trip ID, Device Serial No., Driver Name, Vehicle ID, Route ID, Cash (MYR), Cash Pax, Adult Pax, Child Pax, Senior Pax, Disabled Pax, Foreigner - Adult Pax, Foreigner - Child Pax, Cashless (MYR), Cashless Pax, Adult Pax, Child Pax, Senior Pax, Disabled Pax, Foreigner - Adult Pax, Foreigner - Child Pax, Total Transaction Count\r\n"
        let data = dateGenerated + generatedBy

        tabulated.forEach(({
            trxs,
            localTimeGroup_,
            totalAmount_,
            totalRidership_,
            totalTripCount_,
            cashTotalAmount_,
            cashTotalRidership_,
            cashlessTotalAmount_,
            cashlessTotalRidership_,
        }) => {
            data += `\r\n\nDate, ${localTimeGroup_}\r\n`
            data += `Total Trip Count, ${totalTripCount_}\r\n`
            data += `Total Collection (MYR), ${totalAmount_}\r\n`
            data += `Total Pax, ${totalRidership_}\r\n`
            data += header
            let tt = 0
            const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))

            let tot_trans = 0
            // 
            uniqueTrips.forEach((sameTripTrxs) => {
                const totalByTrip = {
                    totalPax: 0,
                    totalTrans:0,
                    uniqueJourney: new Set(),
                    cash: 0,
                    cashPax: 0,
                    cashless: 0,
                    cashlessPax: 0,
                    cashAdult: 0,
                    cashChild: 0,
                    cashSenior: 0,
                    cashOku: 0,
                    cashFAdult: 0,
                    cashFChild: 0,
                    cashlessAdult: 0,
                    cashlessChild: 0,
                    cashlessSenior: 0,
                    cashlessOku: 0,
                    cashlessFAdult: 0,
                    cashlessFChild: 0,
                }
                sameTripTrxs.forEach(({
                    userId,
                    amount,
                    noOfAdult,
                    noOfChild,
                    noOfSenior,
                    noOfOku,
                    noOfForeignAdult,
                    noOfForeignChild,
                    journeyId,
                    paymentType
                }) => {
                    const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild;

                    const isCashless = userId || paymentType != 'OFFLINE';
                    const isCash = !userId && paymentType == 'OFFLINE';

                    totalByTrip.totalPax += totalPax;

                    totalByTrip.cash += isCash ? +amount : 0;
                    totalByTrip.cashPax += isCash ? +totalPax : 0;

                    totalByTrip.cashless += isCashless ? +amount : 0;
                    totalByTrip.cashlessPax += isCashless ? +totalPax : 0;

                    totalByTrip.cashAdult += isCash ? noOfAdult : 0;
                    totalByTrip.cashChild += isCash ? noOfChild : 0;
                    totalByTrip.cashSenior += isCash ? noOfSenior : 0;
                    totalByTrip.cashOku += isCash ? noOfOku : 0;
                    totalByTrip.cashFAdult += isCash ? noOfForeignAdult : 0;
                    totalByTrip.cashFChild += isCash ? noOfForeignChild : 0;
                    totalByTrip.cashlessAdult += isCashless ? noOfAdult : 0;
                    totalByTrip.cashlessChild += isCashless ? noOfChild : 0;
                    totalByTrip.cashlessSenior += isCashless ? noOfSenior : 0;
                    totalByTrip.cashlessOku += isCashless ? noOfOku : 0;
                    totalByTrip.cashlessFAdult += isCashless ? noOfForeignAdult : 0;
                    totalByTrip.cashlessFChild += isCashless ? noOfForeignChild : 0;
                    journeyId && totalByTrip.uniqueJourney.add(journeyId);
                })
                totalByTrip.totalTrans = totalByTrip.uniqueJourney.size
               
                // Round values to fix precision issues before writing
                totalByTrip.cash = +totalByTrip.cash.toFixed(2);
                totalByTrip.cashless = +totalByTrip.cashless.toFixed(2);
                tt = tt + totalByTrip.totalTrans

                data += `${moment(sameTripTrxs[0].startedAt).format('HH:mm:ss')}, ${moment(sameTripTrxs[0].endedAt).format('HH:mm:ss')}, ${sameTripTrxs[0].tripId}, ${sameTripTrxs[0].agencyTripId}, ${sameTripTrxs[0].deviceSerialNumber}, ${sameTripTrxs[0].driverName}, ${sameTripTrxs[0].vehicleRegistrationNumber}, ${sameTripTrxs[0].routeShortName}, ${totalByTrip.cash}, ${totalByTrip.cashPax}, ${totalByTrip.cashAdult}, ${totalByTrip.cashChild}, ${totalByTrip.cashSenior}, ${totalByTrip.cashOku}, ${totalByTrip.cashFAdult}, ${totalByTrip.cashFChild}, ${totalByTrip.cashless}, ${totalByTrip.cashlessPax}, ${totalByTrip.cashlessAdult}, ${totalByTrip.cashlessChild}, ${totalByTrip.cashlessSenior}, ${totalByTrip.cashlessOku}, ${totalByTrip.cashlessFAdult}, ${totalByTrip.cashlessFChild}, ${totalByTrip.totalTrans}\r\n`
               
            })

            data += `\r\n, , TOTAL, , , , , , ${cashTotalAmount_}, ${cashTotalRidership_}, , , , , , , ${cashlessTotalAmount_}, ${cashlessTotalRidership_}, , , , , , , ${tt}\r\n`

        })

        exportData(data, `Trip Collection ${moment().format('YYYYMMDD_HHmmss')}.csv`, 'text/csv;charset=utf-8;')

    }, [tabulated])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Trip Collection</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        transaction && transaction.length > 0 ?
                            <div>
                                {
                                    routesArr && driverList && vehicleList ?
                                        <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' > <InfoCircle /> Trip Collection only shows data for the past 3 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={tabulated} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default TripCollectionPage