import { Appbar, ScrollToTop, Sidebar } from './components';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import { configureStore } from './redux/store';
import { toast, ToastContainer } from 'react-toastify';

import { EditorialStop, EditorialRoute, EditorialVehicle, EditorialDriver, EditorialFare, EditorialSchedule, EditorialWaypoint, EditorialDevice, EditorialZone } from './pages/EditorialPage';
import RealTimePage from './pages/RealTimePage';
import SignUp from './pages/SignUpPage';
import SignIn from './pages/SignInPage';
import ToggleAgencyPage from './pages/ToggleAgencyPage';

import TransactionPage from './pages/TransactionPage';
import ClaimReportPage from './pages/ClaimReportPage';
import BusTransferReportPage from './pages/BusTransferReportPage';
import IsbsfReportPage from './pages/IsbsfReportPage';
import TotalKmPage from './pages/TotalKmPage';
import GTFSPage from './pages/GTFSPage/GTFSPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import ProfilePage from './pages/ProfilePage';
import VerifyNewBasic from './pages/VerifyNewBasic';
import SettlementPage from './pages/SettlementPage';
import CollectionSummary from './pages/CollectionSummary';
import DailyTransactionReportPage from './pages/DailyTransactionReportPage';
import FareCollectionPage from './pages/FareCollectionPage';
import HistoryLogPage from './pages/HistoryLogPage';
import TripExperiencePage from './pages/TripExperiencePage';
// import Schedule from './pages/Schedule';
import ScheduleV2Page from './pages/ScheduleV2Page';
import SchedulesV2EditorialPage from './pages/SchedulesV2EditorialPage';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/bootstrap.min.css';
import AgencyProfilePage from './pages/AgencyProfilePage';
import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import LogoutPage from './pages/LogoutPage';
import ResetPassword from './pages/ResetPassword';
import TripCollectionPage from './pages/TripCollectionPage';
import AnalyticsPage from './pages/AnalyticsPage';
import VehicleTripsPage from './pages/vehicle-trips';
import DriverCollectionPage from './pages/DriverCollectionPage';
import RouteCollectionPage from './pages/RouteCollectionPage';
import VehicleCollectionPage from './pages/VehicleCollectionPage';
import SalesReportPage from './pages/SalesReportPage';
import TripMissedReportPage from './pages/TripMissedReportPage';

import ClaimReportPageApad from './pages/ClaimReportPageApad';

import { GENERAL } from './config';
import SuperPage from './pages/SuperPage';
import ApadHomePage from './pages/ApadHomePage';
import CollectionByRoutePage from './pages/CollectionByRoutePage';
import ApadPmhsScoreboardPage from './pages/ApadPmhsScoreboardPage';
import GPSViewPage from './pages/GPSViewPage';

const { SUPER_ADMIN } = GENERAL

const ShowErrorAndRedirect = () => {
  const [go, setGo] = useState(false)

  useEffect(() => {
    toast.error('UnAuthorized Access')
    const delay = setTimeout(() => {
      setGo(true)
      clearTimeout(delay)
    }, 5000)
  }, [])

  const redirect = useMemo(() => {
    if (go) {
      return <Redirect to='/' />
    }
    return <div style={{ width: '100vw', height: '100vh' }} className='d-flex flex-column justify-content-center align-items-center' >
      <Spinner animation="grow" />
      <h4>Redirecting to secured page</h4>
    </div>
  }, [go])
  return redirect
}


const ProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user.jwtToken && global.store.getState().user.userType != 'APAD' ? (children) : <Redirect to='/signin' />
  )} />
)
const ProtectedRouteWithApad = ({ children, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user.jwtToken ? (children) : <Redirect to='/signin' />
  )} />
)

const AdminProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user.isAdmin ||  global.store.getState().user.userType === 'SUPER_ADMIN' ? (children) : <ShowErrorAndRedirect />
  )} />
)
const ApadOnlyProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest} render={(props) => (
    global.store.getState().user.userType === 'APAD' ? (children) : <ShowErrorAndRedirect />
  )} />
)

const SuperAdminProtectedRoute = ({ children, ...rest }) => (
  <Route {...rest} render={(props) => {
    const { user } = global.store.getState()
    return (user.isAdmin && user.userType === SUPER_ADMIN) ? (children) : <ShowErrorAndRedirect />
  }} />
)

const WithSidebar = ({ children, ...rest }) => (
  <ProtectedRoute {...rest}>
    <Sidebar >
      <Appbar />
      <div className='position-relative' >
        {children}
      </div>
    </Sidebar>
  </ProtectedRoute>
)
const WithSidebarIncludingApad = ({ children, ...rest }) => (
  <ProtectedRouteWithApad {...rest}>
    <Sidebar >
      <Appbar />
      <div className='position-relative' >
        {children}
      </div>
    </Sidebar>
  </ProtectedRouteWithApad>
)

const AdminOnly = ({ children, ...rest }) => (
  <AdminProtectedRoute {...rest}>
    <Sidebar >
      <Appbar />
      <div className='position-relative' >
        {children}
      </div>
    </Sidebar>
  </AdminProtectedRoute>
)
const ApadAdminOnly = ({ children, ...rest }) => (
  <ApadOnlyProtectedRoute {...rest}>
    {/* <Sidebar > */}
      {/* <Appbar /> */}
      <div className='position-relative' >
        {children}
      </div>
    {/* </Sidebar> */}
  </ApadOnlyProtectedRoute>
)
const ApadAdminOnlyWithSidebar = ({ children, ...rest }) => (
  <ApadOnlyProtectedRoute {...rest}>
    <Sidebar >
      <Appbar />
      <div className='position-relative' >
        {children}
      </div>
    </Sidebar>
  </ApadOnlyProtectedRoute>
)

const SuperOnly = ({ children, ...rest }) => (
  <SuperAdminProtectedRoute {...rest}>
    <Sidebar >
      <Appbar />
      <div className='position-relative' >
        {children}
      </div>
    </Sidebar>
  </SuperAdminProtectedRoute>
)

function App() {
  const store = configureStore();
  const persistor = persistStore(store);
  global.store = store;

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <WithSidebar exact path='/' ><RealTimePage /></WithSidebar>
            <ApadAdminOnly exact path='/agency-select' ><ToggleAgencyPage /></ApadAdminOnly>
            <ApadAdminOnlyWithSidebar exact path='/apad-home' ><ApadHomePage /></ApadAdminOnlyWithSidebar>
            <Route path='/signup'><SignUp /></Route>
            <Route path='/logout'><LogoutPage /></Route>
            <Route path='/signin' ><SignIn /></Route>
            <WithSidebar path='/schedule/editorial/:routeId' ><SchedulesV2EditorialPage /></WithSidebar>
            <Route path='/reset-password/:emailCode' ><ResetPassword /></Route>
            <Route path='/verify-email/:emailCode' ><VerifyEmailPage /></Route>
            <Route path='/verify-me/:emailCode' ><VerifyNewBasic /></Route>
            <WithSidebar path='/editorial/route' ><EditorialRoute /></WithSidebar>
            <WithSidebar path='/editorial/stop' ><EditorialStop /></WithSidebar>
            <WithSidebar path='/editorial/device' ><EditorialDevice /></WithSidebar>
            <WithSidebar path='/editorial/vehicle' ><EditorialVehicle /></WithSidebar>
            <WithSidebar path='/editorial/driver' ><EditorialDriver /></WithSidebar>
            <WithSidebar path='/editorial/fare/:routeId' ><EditorialFare /></WithSidebar>
            <WithSidebar path='/editorial/schedule/:routeId' ><EditorialSchedule /></WithSidebar>
            <WithSidebar path='/editorial/waypoint/:routeId' ><EditorialWaypoint /></WithSidebar>
            <WithSidebar path='/editorial/zone/:routeId' ><EditorialZone /></WithSidebar>
            <WithSidebar path='/transaction' ><TransactionPage /></WithSidebar>

            <WithSidebarIncludingApad path='/claim' ><ClaimReportPage /></WithSidebarIncludingApad>
            <WithSidebarIncludingApad path='/missed-trips' ><TripMissedReportPage /></WithSidebarIncludingApad>
            <WithSidebarIncludingApad path='/isbsf' ><IsbsfReportPage /></WithSidebarIncludingApad>
            <WithSidebarIncludingApad path='/sales' ><SalesReportPage /></WithSidebarIncludingApad>
            <WithSidebarIncludingApad path='/bus-transfer' ><BusTransferReportPage /></WithSidebarIncludingApad>
            <WithSidebarIncludingApad path='/scoreboard' ><ApadPmhsScoreboardPage /></WithSidebarIncludingApad>

            <WithSidebar path='/apad-trips' ><ClaimReportPageApad /></WithSidebar>

            <WithSidebar path='/custom' ><TransactionPage /></WithSidebar>
            <WithSidebar path='/transaction-report/:date' ><DailyTransactionReportPage /></WithSidebar>
            <WithSidebar path='/settlement' ><SettlementPage /></WithSidebar>
            <WithSidebar path='/quick-start-gtfs' ><GTFSPage /></WithSidebar>
            <WithSidebar path='/collection-summary' ><CollectionSummary /></WithSidebar>
            <WithSidebar path='/fare-collection' ><FareCollectionPage /></WithSidebar>
            <WithSidebar path='/total-km' ><TotalKmPage /></WithSidebar>
            <WithSidebarIncludingApad path='/history-log' ><HistoryLogPage /></WithSidebarIncludingApad>
            <Route path='/gps-view' ><GPSViewPage /></Route>
            <WithSidebar path='/trip-experience' ><TripExperiencePage /></WithSidebar>
            {/* <WithSidebar path='/schedule' ><Schedule /></WithSidebar> */}
            <WithSidebar path='/schedule-new' ><ScheduleV2Page /></WithSidebar>
            <WithSidebar path='/profile' ><ProfilePage /></WithSidebar>
            <WithSidebar path='/trip-collection' ><TripCollectionPage /></WithSidebar>
            <AdminOnly path='/agency' ><AgencyProfilePage /></AdminOnly>
            <WithSidebar path='/analytics' ><AnalyticsPage /></WithSidebar>
            <WithSidebar path='/vehicle-trips/:vehicleId' ><VehicleTripsPage /></WithSidebar>
            <WithSidebar path='/driver-collection' ><DriverCollectionPage /></WithSidebar>
            <WithSidebar path='/route-collection' ><RouteCollectionPage /></WithSidebar>
            <WithSidebar path='/collection-route' ><CollectionByRoutePage /></WithSidebar>
            <WithSidebar path='/vehicle-collection' ><VehicleCollectionPage /></WithSidebar>
            <SuperOnly path='/super'><SuperPage/></SuperOnly>

            {/* <WithSidebar path='/report' ><ContentWrapper>
              <h1>Report</h1>
              <h5>This feature will be available soon!</h5>
            </ContentWrapper>
            </WithSidebar> */}


            <Route path='/*'>
              <h1>404 - not found </h1>
            </Route>

          </Switch>
        </BrowserRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>
  );
}

export default App;
