import { connect } from "react-redux";
import GPSViewPage from "./GPSViewPage";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {}
)(GPSViewPage)