import React, { useCallback, useEffect, useState } from 'react'
import PolylineUtils from '@mapbox/polyline'

import { Modal, StaticMap } from '..'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers'
import { toast } from 'react-toastify'

const PreviewMapModal = ({ route, show, onHide, title, tripId, direction, size = 'lg' }) => {
    const [stops, setStops] = useState([])
    const [tripLog, setTripLog] = useState([])

    const handleDecodedPolyline = () => {
        if (!route.polygon) return null
        return PolylineUtils.decode(route.polygon)
    }

    useEffect(() => {
        handleGetLogs()
        handleGetStops()
        if (show) {
            setStops([])
        }
    }, [show])

    const handleGetStops = useCallback(() => {
        if (route?.id) {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ROUTE_GET}/${route.id}`
            }).then((route) => {
                const newdata = []

                route.stops.forEach(({ latitude, longitude, name, directionId }) => {
                    if (Number(directionId) === Number(direction))
                        newdata.push([latitude, longitude, name])
                })

                setStops(newdata)
            }).catch(e => { })
        }
    }, [route])

    const handleGetLogs = useCallback(async () => {
        if (tripId) {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: API_END_POINTS.GET_TRIP_LOG,
                params: {
                    tripId
                }
            })
            setTripLog(data)
        }
    }, [tripId])

    const decodedPolyline = handleDecodedPolyline()
    return (
        <Modal size={size} backdrop='static' title={title || ''} show={show} onHide={onHide} >
            {
                decodedPolyline !== null ?
                    tripLog?.length > 0 ?
                        <StaticMap height={'60vh'} istripCenterVehicle={true} tripInfo={title} tripLog={tripLog} markers={stops} center={decodedPolyline[Math.floor(decodedPolyline.length / 2)]} polylines={[decodedPolyline]} polylinesPathOpt={[{ color: route.colorCode || 'red' }]} />
                        :
                        <StaticMap height={'60vh'} markers={stops} center={decodedPolyline[Math.floor(decodedPolyline.length / 2)]} polylines={[decodedPolyline]} polylinesPathOpt={[{ color: route.colorCode || 'red' }]} />
                    :
                    stops.length > 0 ?
                        <StaticMap markers={stops} />
                        :
                        <StaticMap />
            }
        </Modal>
    )
}

export default PreviewMapModal