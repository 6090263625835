import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'
import PolylineUtils from '@mapbox/polyline'
import * as geolib from 'geolib';
import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { }, user }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')
    const [canFilter, setCanFilter] = useState(false)

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        if (!selectFromDate || !selectToDate) {
            return toast.error("Please select the date to search")

        }
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }
        // 
        // if (!route || route === 'null') { return toast.error("Fill all data to generate report!") }
        // 
        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        // document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        setCanFilter(false)

        onClearQuery()
    }
    const setperiod = async (e) => {
        // 
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)
        setCanFilter(true)

    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='From Date' >
                                        <Form.Control required id='date-from' max={moment().format('YYYY-MM-DD')} min={user.userType != 'SUPER_ADMIN' ? moment().subtract(3, 'months').format('YYYY-MM-DD') : moment().subtract(12, 'months').format('YYYY-MM-DD')} onChange={(e) => setFromDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control required id='date-to' min={moment(selectFromDate).format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 23:59:59` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>

                            </Row>

                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const TripMissedReportPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)
    const [ultraFilteredTripCollection, setUltraFilteredTripCollection] = useState(null)
    const [toDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    const [routesData, setRoutesData] = useState({})
    const [tripLog, setTripLog] = useState({})
    const [tripLogWithS, setTripLogWithS] = useState({})
    const [tripLogStart, setTripLogStart] = useState({})
    const [tripLogKM, setTripLogKM] = useState({})

    const [timetable, setTimetable] = useState({});
    const [vehicleArray, setVehicleArray] = useState([]);

    const handleGetAllVehicle = async () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`,
        }).then(async (data) => {
            await setVehicleArray(data)
        }).catch(e => { })
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then(async (data) => {
            await setRoutesArr(data)
            // handleRouteData()
        }).catch(e => { })
    }

    useEffect(() => {
        const routeWithStops = {}
        // 
        routesArr && routesArr.forEach(({ id }) => {
            if (!routeWithStops[id]) {
                ApiRequest.fetch({
                    method: 'get',
                    url: `${API_END_POINTS.STOP_GET_ALL}`,
                    params: {
                        routeId: id
                    }
                }).then((data) => {
                    // 
                    // return data
                    routeWithStops[id] = data
                })
            }
        })
        setRoutesData(routesData => ({ ...routesData, ...routeWithStops }))
        // 
        // 

    }, [routesArr]);

    useEffect(() => {
        handleGetAllRoute()
        handleGetAllVehicle();
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Route No.',
            accessor: 'routeId',
            // disableFilters: true
        },
        {
            Header: 'OD',
            accessor: 'routeName',
            // disableFilters: true
        },
        {
            Header: 'Trip Date/Time',
            accessor: 'tripDetail',
            // disableFilters: true
        },

        // {
        //     Header: 'Trip ID',
        //     accessor: 'tripId',
        //     // disableFilters: true
        // },

        {
            Header: 'Bus Plate Number',
            accessor: 'busPlate',
            // disableFilters: true
        },
        {
            Header: 'Vehicle Age',
            accessor: 'busAge',
            // disableFilters: true
        },
        {
            Header: 'Km Rate',
            accessor: 'kmRate',
            // disableFilters: true
        },

    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = ultraFilteredTripCollection || filteredTripCollection
        if (!mainData) return []
        // console.log("mainData",mainData);
        const sortedDataWithDirRoute = _.orderBy(mainData, ['routeId'], ['asc'])
        const sortedDataWithDir = _.orderBy(sortedDataWithDirRoute, ['obIb'], ['asc'])

        const sortedData = _.orderBy(sortedDataWithDir, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['asc', 'asc'])


        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        });

        const groupedTestByRoute = _(addedLocalTime)
            .groupBy(item => item.routeId)
            .mapValues(routeGroup => _.sortBy(routeGroup, 'routeId'))
            .value();

        function dict_reverse(obj) {
            let new_obj = {}
            let rev_obj = Object.keys(obj).reverse();
            rev_obj.forEach(function (i) {
                new_obj[i] = obj[i];
            })
            return new_obj;
        }
        const groupedTestByRouteRev = dict_reverse(groupedTestByRoute)

        Object.entries(groupedTestByRouteRev).forEach(([localTimeGroup, trxs]) => {
            const groupedTest = _.groupBy(trxs, item => `"${item.localDate}"`)

            Object.entries(groupedTest).forEach(([localTimeGroup, trxs]) => {
                // 
                const accumulativeTrip = {
                    'datetime_': moment(trxs[0].scheduledAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                    'checkoutTime_': moment(trxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss'),
                    'uniqueTrip_': new Set(),
                    'totalTripCount_': 0,
                    'uniqueDriver_': new Set(),
                    'totalUniqueDriverCount_': 0,
                    'uniqueVehicle_': new Set(),
                    'totalUniqueVehicleCount_': 0,
                    'uniqueJourney_': new Set(),
                    'totalTransaction_': 0,
                    'totalAmount_': 0,
                    'noOfAdult': 0,
                    'noOfChild': 0,
                    'noOfSenior': 0,
                    'totalChild': 0,
                    'totalSenior': 0,
                    'totalAdult': 0,
                    'noOfOku': 0,
                    'noOfForeignAdult': 0,
                    'noOfForeignChild': 0,
                    'totalRidership_': 0,
                    'cashTotalAmount_': 0,
                    'cashTotalRidership_': 0,
                    'cashlessTotalAmount_': 0,
                    'cashlessTotalRidership_': 0,
                }
                trxs.map((row) => {
                    const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                    accumulativeTrip['uniqueDriver_'].add(row.driverName)
                    accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                    accumulativeTrip['uniqueTrip_'].add(row.tripId)
                    accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                    accumulativeTrip['totalAmount_'] += +row.amount
                    accumulativeTrip['noOfAdult'] += +row.noOfAdult
                    accumulativeTrip['noOfChild'] += +row.noOfChild
                    accumulativeTrip['noOfSenior'] += +row.noOfSenior
                    accumulativeTrip['noOfOku'] += +row.noOfOku
                    accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                    accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                    accumulativeTrip['totalRidership_'] += totalPax

                    accumulativeTrip['cashTotalAmount_'] += row.userId ? 0 : +row.amount
                    accumulativeTrip['cashTotalRidership_'] += row.userId ? 0 : totalPax

                    accumulativeTrip['cashlessTotalAmount_'] += row.userId ? +row.amount : 0
                    accumulativeTrip['cashlessTotalRidership_'] += row.userId ? totalPax : 0
                })

                accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
                accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
                accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
                accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
                accumulativeTrip['totalAdult'] = accumulativeTrip.noOfAdult
                accumulativeTrip['localTimeGroup_'] = localTimeGroup.split("+")[0]
                accumulativeTrip['trxs'] = trxs


                //format amount
                accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
                accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
                accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

                returnData.push(accumulativeTrip)
            })
        });

        return returnData
    }, [tripCollection, filteredTripCollection, ultraFilteredTripCollection])

    // claim report data start
    const claimData = useMemo(() => {
        if (!tabulated || tabulated.length <= 0) return [];
        const returnData = []
        // console.log('tabulated: \n', tabulated);

        tabulated.forEach(({
            trxs,
            localTimeGroup_,
            totalAmount_,
            totalRidership_,
            totalTripCount_,
            cashTotalAmount_,
            cashTotalRidership_,
            cashlessTotalAmount_,
            cashlessTotalRidership_,
        }) => {

            const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))

            uniqueTrips.forEach((sameTripTrxs) => {
                let isComplete = false
                if (sameTripTrxs[0]?.apadPolygon?.length > 0) {
                    const decodedPolyline = PolylineUtils.decode(sameTripTrxs[0].apadPolygon)
                    const arrIb = []
                    const arrIbBetween = []
                    if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                        decodedPolyline?.forEach((poly, index) => {
                            if (index === 0 || index === 5) {
                                for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                    const isNear = geolib.isPointWithinRadius(
                                        { latitude: poly[0], longitude: poly[1] },
                                        { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                        200
                                    );

                                    if (isNear) {
                                        // check if inbound and scheduled?
                                        arrIb.push(poly)
                                        break;
                                    }
                                }
                            }
                        });

                        for (let index = 1; index < decodedPolyline.length - 1; index++) {
                            const element = decodedPolyline[index];
                            for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                const isNear = geolib.isPointWithinRadius(
                                    { latitude: element[0], longitude: element[1] },
                                    { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                    200
                                );

                                if (isNear) {
                                    // check if inbound and scheduled?
                                    arrIbBetween.push(element)
                                    break;
                                }
                            }
                        }
                    }

                    if (arrIb?.length >= 2 && arrIbBetween?.length >= 1) isComplete = true
                }

                if (!sameTripTrxs[0].startedAt || !isComplete) {
                    const totalByTrip = {
                        totalPax: 0,
                        totalAmount: 0,
                        cash: 0,
                        cashPax: 0,
                        cashless: 0,
                        cashlessPax: 0,
                        cashAdult: 0,
                        cashChild: 0,
                        cashSenior: 0,
                        cashOku: 0,
                        cashFAdult: 0,
                        cashFChild: 0,
                        cashlessAdult: 0,
                        cashlessChild: 0,
                        cashlessSenior: 0,
                        cashlessOku: 0,
                        cashlessFAdult: 0,
                        cashlessFChild: 0,
                        noOfAdult: 0,
                        noOfChild: 0,
                        noOfSenior: 0,
                        noOfOku: 0,
                        trxsTime: []
                    }

                    sameTripTrxs.forEach(({
                        userId,
                        amount,
                        noOfAdult,
                        noOfChild,
                        noOfSenior,
                        noOfOku,
                        noOfForeignAdult,
                        noOfForeignChild,
                        journeyCreated,
                        journeyEnded
                    }) => {
                        const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild
                        totalByTrip.routeId = sameTripTrxs[0].routeShortName
                        totalByTrip.routeName = sameTripTrxs[0].routeName
                        totalByTrip.tripId = sameTripTrxs[0].tripId
                        totalByTrip.tripDetail = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD-MM-YYYY HH:mm:ss') : ''

                        // totalByTrip.tripStart = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '-'
                        // totalByTrip.tripEnd = moment(sameTripTrxs[0].endedAt).isValid() ? moment(sameTripTrxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '-'
                        // totalByTrip.serviceStart = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '00:00'
                        // totalByTrip.status = sameTripTrxs[0].endedAt != null ? 'COMPLETE' : 'NOT COMPLETE'
                        totalByTrip.busPlate = sameTripTrxs[0]?.vehicleRegistrationNumber ? sameTripTrxs[0]?.vehicleRegistrationNumber : 'N/A'
                        totalByTrip.driverIdentification = sameTripTrxs[0].driverIdentificationNumber
                        totalByTrip.direction = sameTripTrxs[0].obIb == 1 ? 'OB' : sameTripTrxs[0].obIb == 2 ? 'IB' : 'LOOP'
                        // totalByTrip.totalAmount += Number(sameTripTrxs[0].amount)
                        // totalByTrip.noOfAdult += (Number(noOfAdult) + Number(noOfForeignAdult))
                        // totalByTrip.noOfChild += (Number(noOfChild) + Number(noOfForeignChild))
                        // totalByTrip.noOfSenior += Number(noOfSenior)
                        // totalByTrip.noOfOku += Number(noOfOku)
                        // totalByTrip.cash += userId ? 0 : amount
                        // totalByTrip.cashPax += userId ? 0 : totalPax
                        // totalByTrip.cashless += userId ? amount : 0
                        // totalByTrip.totalAmount += amount
                        // totalByTrip.cashlessPax += userId ? totalPax : 0
                        // totalByTrip.cashAdult += userId ? 0 : noOfAdult
                        // totalByTrip.cashChild += userId ? 0 : noOfChild
                        // totalByTrip.cashSenior += userId ? 0 : noOfSenior
                        // totalByTrip.cashOku += userId ? 0 : noOfOku
                        // totalByTrip.cashFAdult += userId ? 0 : noOfForeignAdult
                        // totalByTrip.cashFChild += userId ? 0 : noOfForeignChild
                        // totalByTrip.cashlessAdult += userId ? noOfAdult : 0
                        // totalByTrip.cashlessChild += userId ? noOfChild : 0
                        // totalByTrip.cashlessSenior += userId ? noOfSenior : 0
                        // totalByTrip.cashlessOku += userId ? noOfOku : 0
                        // totalByTrip.cashlessFAdult += userId ? noOfForeignAdult : 0
                        // totalByTrip.cashlessFChild += userId ? noOfForeignChild : 0
                        // totalByTrip.punctuality = sameTripTrxs[0].scheduledAt && sameTripTrxs[0].startedAt ? Math.abs(Number(moment(sameTripTrxs[0].scheduledAt).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].startedAt).format('X'))) <= 500 ? 'ON TIME' : 'LATE' : ' Not Scheduled'
                        // totalByTrip.trxsTime.push(userId ? moment(journeyCreated).format('X') : moment(journeyEnded).format('X'))
                        totalByTrip.busAge = sameTripTrxs[0]?.VehicleAge ? moment().year() - sameTripTrxs[0]?.VehicleAge : 'N/A'
                        totalByTrip.kmRate = sameTripTrxs[0]?.kmRate || 'N/A';

                        totalByTrip.totalClaim = 0
                        totalByTrip.monthlyPass = '-'
                        totalByTrip.jkm = '-'
                        totalByTrip.maim = '-'
                    })

                    // const numberArray = totalByTrip.trxsTime.map(Number);

                    // totalByTrip.salesStart = isNaN(moment.unix(Math.min(...numberArray))) ? '-' : moment.unix(Math.min(...numberArray)).format('DD-MM-YYYY HH:mm:ss (ddd)');
                    // totalByTrip.salesEnd = isNaN(moment.unix(Math.max(...numberArray))) ? '-' : moment.unix(Math.max(...numberArray)).format('DD-MM-YYYY HH:mm:ss (ddd)');

                    returnData.push(totalByTrip);
                }
            })
        })

        // 1. go through each day (tabulated)
        // 2. group the transactions (trxs) of the day by route, direction, then day
        // 3. go through the grouped data, check for each day if there's any trip missing. 
        // 3.1 sort the data by scheduledAt
        // - - exclude adhoc trip from the list
        // 3.2 check if length of the data === length of schedules
        // 3.3 if not, go through the data and find the missing scheduled time schedules
        tabulated.forEach(({ trxs, datetime_ }) => {
            let scheduleDay;

            try {
                const day = (datetime_.match(/\((.*?)\)/)[1].toLowerCase());

                if (day == 'sun') scheduleDay = 'sunday';
                else if (day == 'mon') scheduleDay = 'monday';
                else if (day == 'tue') scheduleDay = 'tuesday';
                else if (day == 'wed') scheduleDay = 'wednesday';
                else if (day == 'thu') scheduleDay = 'thursday';
                else if (day == 'fri') scheduleDay = 'friday';
                else if (day == 'sat') scheduleDay = 'saturday';
                else scheduleDay = '';

                if (!scheduleDay) return;
                
                const groupedTrxsByRoute = _.groupBy(
                    trxs.filter(trx => !trx.adHoc), // Exclude adHoc trips
                    'routeId'
                );

                const groupedTrxsByRouteDirection = _.mapValues(groupedTrxsByRoute, (routeData) =>
                    _.groupBy(routeData, 'obIb')
                );

                const schedulesGroupedByDay = _.mapValues(groupedTrxsByRouteDirection, (directionData) =>
                    _.mapValues(directionData, (directionGroup) =>
                        _.groupBy(directionGroup, 'scheduledAt')
                    )
                );

                const groupedData = schedulesGroupedByDay;
                if (!groupedData || Object.keys(groupedData).length === 0) {
                    console.warn('groupedData is empty or invalid.');
                    return;
                }

                // console.log('grouped data: \n', schedulesGroupedByDay);

                /*
                Object.keys(groupedData).forEach(key => {
                    const routeDirectionData = groupedData[key];
                    const outboundData = routeDirectionData[1];
                    const inboundData = routeDirectionData[2];

                    let schedule;
                    try {
                        schedule = timetable[key];
                        if (!schedule) return;
                    } catch(error) {
                        console.warn('No timetable found, returning');
                        return;
                    }

                    const outboundSchedules = schedule[1][scheduleDay];
                    const inboundSchedules = schedule[2][scheduleDay];

                    if (!inboundData && !outboundData) {
                        console.warn(`No data for key: ${key}`);
                        return; // Skip this iteration entirely if both inbound and outbound are missing
                    }

                    // Process Inbound Schedules
                    if (inboundData) {
                        const inboundKeys = Object.keys(inboundData);
                        const inboundKeysConverted = inboundKeys.map(k => ({
                            original: k,
                            converted: moment(k).utcOffset(8).format("HH:mm:ss")
                        }));

                        const inboundMissingSchedules = inboundSchedules.filter(schedule => {
                            return !inboundKeysConverted.some(({ converted }) => converted === schedule.start_time);
                        });

                        inboundMissingSchedules.forEach(sch => {
                            const missingDate = inboundKeysConverted.length > 0 ? inboundKeysConverted[0].original : null;
                            const dateInGMT8 = missingDate
                                ? moment(missingDate).utcOffset(8).format("DD-MM-YYYY")
                                : "N/A";

                            const vehicle = vehicleArray.find(v => v.id === Number(sch.vehicle_id)) || {};

                            const data = {
                                routeId: trxs[0].routeShortName,
                                routeName: trxs[0].routeName,
                                tripDetail: `${dateInGMT8} ${sch.start_time}`, // Full date and time
                                busPlate: vehicle.registrationNumber || 'N/A',
                                busAge: vehicle.vehicleAge ? moment().year() - vehicle.vehicleAge : 'N/A',
                                kmRate: 'N/A'
                            };

                            returnData.push(data);
                        });
                    }

                    // Process Outbound Schedules
                    if (outboundData) {
                        const outboundKeys = Object.keys(outboundData);
                        const outboundKeysConverted = outboundKeys.map(k => ({
                            original: k,
                            converted: moment(k).utcOffset(8).format("HH:mm:ss")
                        }));

                        const outboundMissingSchedules = outboundSchedules.filter(schedule => {
                            return !outboundKeysConverted.some(({ converted }) => converted === schedule.start_time);
                        });

                        outboundMissingSchedules.forEach(sch => {
                            const missingDate = outboundKeysConverted.length > 0 ? outboundKeysConverted[0].original : null;
                            const dateInGMT8 = missingDate
                                ? moment(missingDate).utcOffset(8).format("YYYY-MM-DD")
                                : "N/A";

                            const vehicle = vehicleArray.find(v => v.id === Number(sch.vehicle_id)) || {};

                            const data = {
                                routeId: trxs[0].routeId,
                                routeName: trxs[0].routeName,
                                tripDetail: `${dateInGMT8} ${sch.start_time}`, // Full date and time
                                busPlate: vehicle.registrationNumber || 'N/A',
                                busAge: vehicle.vehicleAge || 'N/A',
                                kmRate: 'N/A'
                            };

                            returnData.push(data);
                        });
                    }
                });

                */
                Object.keys(groupedData).forEach(key => {
                    const routeDirectionData = groupedData[key];
                    
                    // Check for loop route scenario
                    const directions = [1, 2, 3]; // Outbound, Inbound, Loop
                    const existingDirections = directions.filter(dir => routeDirectionData[dir]);
                
                    // console.log('existingDirections: ', existingDirections)
                    let schedule;
                    try {
                        schedule = timetable[key];
                        if (!schedule) {
                            console.warn(`No schedule found for route ${key}`);
                            return;
                        }
                    } catch(error) {
                        console.warn(`Error accessing timetable for route ${key}:`, error);
                        return;
                    }
                
                    // If no data exists for any direction
                    if (existingDirections.length === 0) {
                        console.warn(`No data found for any direction in route ${key}`);
                        return;
                    }
                
                    // Process each existing direction
                    existingDirections.forEach(direction => {
                        const directionData = routeDirectionData[direction];
                        const directionSchedules = schedule[direction][scheduleDay];
                
                        // Validate direction schedules
                        if (!directionSchedules || directionSchedules.length === 0) {
                            console.warn(`No schedules found for direction ${direction} in route ${key}`);
                            return;
                        }
                
                        const directionKeys = Object.keys(directionData);
                        const directionKeysConverted = directionKeys.map(k => ({
                            original: k,
                            converted: moment(k).utcOffset(8).format("HH:mm:ss")
                        }));
                
                        // Find missing schedules
                        const missingSchedules = directionSchedules.filter(schedule => {
                            return !directionKeysConverted.some(({ converted }) => converted === schedule.start_time);
                        });
                
                        // Process missing schedules
                        missingSchedules.forEach(sch => {
                            const missingDate = directionKeysConverted.length > 0 
                                ? directionKeysConverted[0].original 
                                : null;
                            
                            const dateInGMT8 = missingDate
                                ? moment(missingDate).utcOffset(8).format("YYYY-MM-DD")
                                : "N/A";
                
                            const vehicle = vehicleArray.find(v => v.id === Number(sch.vehicle_id)) || {};
                
                            const data = {
                                routeId: trxs[0].routeId,
                                routeName: trxs[0].routeName,
                                direction: direction === 1 ? 'Outbound' : 
                                           direction === 2 ? 'Inbound' : 
                                           'Loop',
                                tripDetail: `${dateInGMT8} ${sch.start_time}`,
                                busPlate: vehicle.registrationNumber || 'N/A',
                                busAge: vehicle.vehicleAge 
                                    ? moment().year() - vehicle.vehicleAge 
                                    : 'N/A',
                                kmRate: 'N/A'
                            };
                
                            returnData.push(data);
                        });
                    });
                });
            } catch (error) {
                console.error('Unexpected error processing tabulated data:', error);
            }

        });

        // console.log('return data: \n', returnData)
        return returnData
    }, [tabulated]);

    const handleFilterTransactionData = useCallback(async ({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {

        setLoading(true)
        let transactionF
        try {
            const claimdata2 = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.MISSED_COLLECTION_BY_DATE}`,
                params: {
                    timestamp: new Date(),
                    from: selectFromDate,
                    to: selectToDate
                }
            })

            const timetableData = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET_ALL}`,
            })

            // console.log(timetableData);

            const schedulesForRoute = _.groupBy(timetableData, 'route_id');
            const schedulesForRouteDirection = _.mapValues(schedulesForRoute, (routeData) =>
                _.groupBy(routeData, 'direction_id')
            );

            const schedulesGroupedByDay = _.mapValues(schedulesForRouteDirection, (directionData) =>
                _.mapValues(directionData, (directionGroup) =>
                    _.groupBy(directionGroup, 'day')
                )
            );

            // console.log('schedulesGroupedByDay: ', schedulesGroupedByDay);
            setTimetable(schedulesGroupedByDay);
            // 
            // 
            // const transactionWithStart = claimdata2.filter(({ startedAt }) => startedAt !== null);
            const transactionWithStart = claimdata2.filter(({ startedAt, endedAt, scheduledAt }) => (moment(endedAt).diff(moment(startedAt), 'minutes') >= 10 && moment(startedAt).isSameOrAfter(moment('2022-09-17 00:00:00'))) || (moment(scheduledAt).isSameOrAfter(moment('2022-09-17 00:00:00'))));
            // 
            const transactionWithScheduler = transactionWithStart.map((trx) => {

                if (trx.startedAt && !trx.scheduledAt) {

                    // 
                    let start_time = moment(String(trx.startedAt)).format('HH:mm:ss')
                    // 
                    let dayOfTrip = moment(String(trx.startedAt)).format('YYYY-MM-DD')
                    let dayOfTripName = moment(String(trx.startedAt)).format('dddd')
                    let end_time = moment(String(trx.endedAt)).format('HH:mm:ss')



                    let goal = moment(`2022-01-01 ${start_time}`).format("X")
                    let goalEnd = moment(`2022-01-01 ${end_time}`).format("X")
                    // 
                    let timetableDataTemp = timetableData.filter(({ day, direction_id, route_id }) => day.toLowerCase() == dayOfTripName.toLowerCase() && direction_id == trx.obIb && route_id == trx.routeId)
                    let closestStart = timetableDataTemp.reduce(function (prev, curr) {
                        // 
                        // 
                        // 
                        // 
                        let curr_time = moment(`2022-01-01 ${curr.start_time}`).format("X")
                        // let prev_time = moment(`2022-01-01 ${prev.start_time}`).format("X")
                        // 
                        // 
                        return (Math.abs(curr_time - goal) < Math.abs(prev - goal) ? curr_time : prev);
                    }, 0);
                    let closestEnd = timetableDataTemp.reduce(function (prev, curr) {
                        // 

                        let curr_time = moment(`2022-01-01 ${curr.end_time}`).format("X")
                        // let prev_time = moment(`2022-01-01 ${prev.start_time}`).format("X")
                        // 
                        // 
                        return (Math.abs(curr_time - goalEnd) < Math.abs(prev - goalEnd) ? curr_time : prev);
                    }, 0);
                    // 
                    // change
                    // const closestScheduledAt = `${dayOfTrip} ${moment.unix(closestStart).format("HH:mm:ss")}`
                    // const closestScheduledEnd = moment(`${dayOfTrip} ${moment.unix(closestEnd).format("HH:mm:ss")}`).format()

                    const closestScheduledAt = moment(`${dayOfTrip} ${moment.unix(closestStart).format("HH:mm:ss")}`).toISOString();
                    const closestScheduledEnd = moment(`${dayOfTrip} ${moment.unix(closestEnd).format("HH:mm:ss")}`).toISOString();
                    return { ...trx, scheduledAt: closestScheduledAt, scheduledEndTime: closestScheduledEnd, adHoc: true };
                } else {
                    return { ...trx }
                }

            })
            // 
            transactionF = transactionWithScheduler.filter(({ scheduledAt, scheduledEndTime }) => scheduledAt !== null && scheduledEndTime !== null);




            const uniqueTripIds = [...new Set(transactionF.map(obj => obj.tripId))];

            await ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.GET_TRIP_LOG_BULK}`,
                data: {
                    uniqueTripIds
                }
            }).then(async (tripResolve) => {
                tripResolve.forEach(async (data) => {
                    if (data.length > 0) {
                        const tripLogWithStop = data.filter(function (item) {
                            return item.stopId != "null";
                        })
                        const tripLogWithApad = data
                        const tripLogWithStartStop = tripLogWithStop.reduce(
                            (prev, current) =>
                                (prev?.sequence ?? current?.sequence) >= current?.sequence ? current : prev,
                            null,
                        );

                        tripLogWithApad.length > 0 && setTripLog(tripLog => ({ ...tripLog, [tripLogWithApad[0]?.tripId]: tripLogWithApad }))
                        setTripLogWithS(tripLogWithS => ({ ...tripLogWithS, [tripLogWithStop[0]?.tripId]: tripLogWithStop }))
                    }

                })
            }).catch(e => { })

            setTransaction(transactionF)
            const sortedData = _.orderBy(transactionF, [({ scheduledAt }) => new Date(scheduledAt)], ['desc'])
            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            const groupedData = _.groupBy(addedLocalTime, 'localDate')
            setTripCollection(groupedData)
        } catch (error) { }
        finally {
            setLoading(false)
        }

        if (!transactionF) return []

        const transactionFF = transactionF.filter(({ scheduledAt, startedAt }) => scheduledAt != null);
        const filtered = transactionFF.filter(({ createdAt, scheduledAt, routeShortName, driverName, vehicleRegistrationNumber, userId }) => {
            let returnVal = true


            if (selectFromDate) {
                setFromDate(moment(selectFromDate).format('DD-MM-YYYY'))
                // 
                returnVal = new Date(scheduledAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                setToDate(moment(selectToDate).format('DD-MM-YYYY'))
                returnVal = new Date(scheduledAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false
            }


            return true
        })

        const sortedData = _.orderBy(filtered, [({ scheduledAt }) => new Date(scheduledAt)], ['desc'])
        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        const groupedData = _.groupBy(addedLocalTime, 'localDate')
        setFilteredTripCollection(groupedData)
        setUltraFilteredTripCollection(filtered)
    }, [transaction])

    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
        setUltraFilteredTripCollection(null)
    }, [])

    const generateExportCsvData = useCallback(() => {
        let grandTotal = 0
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')
        const reportNameHeader = 'Trip Missed Report' + `\r\n\n`

        const networkOperator = 'Network Area:, EMPTY' + '\r\n'
        const networkArea = `Network Operator:, ${user.agency.name}\r\n`
        const datesGeneratedFor = fromDate && toDate ? `Reporting Period :, ${moment(fromDate).format('MM/DD/YYYY')} - ${(toDate).split('-').join('/')}\r\n` : ""
        const dateGenerated = `Generated At:, ${moment().format('DD/MM/YYYY')}\r\n`
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}\r`
        // const header = "Trip Start, Trip End, Trip ID, Driver Name, Vehicle ID, Route ID, Cash (MYR), Cash Pax, Adult Cash (MYR), Adult Pax, Child Cash (MYR), Child Pax, Senior Cash (MYR), Senior Pax, Disabled Cash (MYR), Disabled Pax, Foreigner - Adult Cash (MYR), Foreigner - Adult Pax, Foreigner - Child Cash (MYR), Foreigner - Child Pax, Cashless (MYR), Cashless Pax, Adult Cashless (MYR), Adult Pax, Child Cashless (MYR), Child Pax, Senior Cashless (MYR), Senior Pax, Disabled Cashless (MYR), Disabled Pax, Foreigner - Adult Cashless (MYR), Foreigner - Adult Pax, Foreigner - Child Cashless (MYR), Foreigner - Child Pax, Total Transaction Count\r\n"
        const header = "Route No.,OD,Trip Date/Time,Trip No.,Bus Plate Number,Vehicle Age,KM Rate,\r\n"
        let data = reportNameHeader + networkArea + networkOperator + datesGeneratedFor + dateGenerated + generatedBy
        // 
        // new start
        // console.log("main tabulated", tabulated);
        const returnData = []
        let routeNGlobal = ''
        let routeSNGlobal = ''

        let totall = 0
        let totallC = 0
        let totallS = 0
        let totallO = 0
        let day = ''
        let routeN = ''
        let routeSN = ''
        let index = 0
        let prevSchTime = ''
        let prevSchDirection = ''
        let currentRoute
        let currentRouteTripCounter = 0

        let isAnyTripComplete = false
        let prevRouteId = null
        let prevRouteSN = null
        let prevRouteName = null
        let prevTripDetail = null
        let prevIndex = null
        let prevBusPlate = null
        let prevBusAge = null
        let prevKmRate = null
        let prevScheduleTime = null
        let PrevIsAnyTripComplete = true


        tabulated.forEach(({
            trxs,
            localTimeGroup_,
            totalAmount_,
            totalRidership_,
            totalTripCount_,
            cashTotalAmount_,
            cashTotalRidership_,
            cashlessTotalAmount_,
            cashlessTotalRidership_,
            totalAdult
        }, indexTop, arrTop) => {

            function preferredOrder(obj, order) {
                var newObject = {};
                for (var i = 0; i < order.length; i++) {
                    if (obj.hasOwnProperty(order[i])) {
                        newObject[order[i]] = obj[order[i]];
                    }
                }
                return newObject;
            }


            if (currentRoute != trxs[0].routeId && indexTop != 0) {
                if (currentRouteTripCounter > 0) {

                    data += `,Total Trip Missed For ${routeSNGlobal} ${routeNGlobal} : ,,,,,${currentRouteTripCounter},\r`
                }

                currentRouteTripCounter = 0
            }





            const groupedTest = _.groupBy(trxs, item => `"${item.obIb}"`)
            let groupedTestEdited = preferredOrder(groupedTest, [
                '"0"', '"1"', '"2"'
            ]);
            let tripMissedDateCounter = 0
            Object.entries(groupedTestEdited).forEach(([localTimeGroup, trxs], indexDirec) => {

                const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))
                function sort_by_key(array) {
                    return array.sort(function (a, b) {
                        var x = moment(a[0].scheduledAt).format('X'); var y = moment(a = b[0].scheduledAt).format('X');
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                }
                function sort_by_key_direction(array) {
                    return array.sort(function (a, b) {
                        var x = a[0].obIb; var y = a = b[0].obIb
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                }
                function sort_by_key_route(array) {
                    return array.sort(function (a, b) {
                        var x = a[0].routeId; var y = a = b[0].routeId
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                }
                const uniqueTripsOrderedDirectionRoute = sort_by_key(uniqueTrips)

                let isAnyRecord = false
                let tripMissedCounter = 0

                uniqueTripsOrderedDirectionRoute.forEach((sameTripTrxs, indexf) => {

                    if (prevSchDirection != sameTripTrxs[0].obIb) {
                        index = 0
                    }
                    prevSchDirection = sameTripTrxs[0].obIb
                    if (!moment(prevSchTime)
                        .isSame(sameTripTrxs[0].scheduledAt)) {

                        index++
                    }
                    prevSchTime = sameTripTrxs[0].scheduledAt

                    let isComplete = false
                    if (sameTripTrxs[0]?.apadPolygon?.length > 0) {
                        const decodedPolyline = PolylineUtils.decode(sameTripTrxs[0].apadPolygon)
                        // 
                        // 
                        const arrIb = []
                        const arrIbBetween = []
                        if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                            decodedPolyline?.forEach((poly, index) => {
                                if (index === 0 || index === 5) {
                                    for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                        const isNear = geolib.isPointWithinRadius(
                                            { latitude: poly[0], longitude: poly[1] },
                                            { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                            200
                                        );
                                        // 
                                        if (isNear) {
                                            // check if inbound and scheduled?
                                            arrIb.push(poly)
                                            break
                                        }

                                    }

                                }
                            });
                            for (let index = 1; index < decodedPolyline.length - 1; index++) {
                                const element = decodedPolyline[index];
                                for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                    const isNear = geolib.isPointWithinRadius(
                                        { latitude: element[0], longitude: element[1] },
                                        { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                        200
                                    );
                                    // 
                                    if (isNear) {
                                        // check if inbound and scheduled?
                                        arrIbBetween.push(element)
                                        break
                                    }

                                }

                            }
                        }

                        if (arrIb?.length >= 2 && arrIbBetween?.length >= 1) {

                            isComplete = true
                            isAnyTripComplete = true
                        }

                    }
                    const totalByTrip = {
                        totalPax: 0,
                        totalAmount: 0,
                        cash: 0,
                        cashPax: 0,
                        cashless: 0,
                        cashlessPax: 0,
                        cashAdult: 0,
                        cashChild: 0,
                        cashSenior: 0,
                        cashOku: 0,
                        cashFAdult: 0,
                        cashFChild: 0,
                        cashlessAdult: 0,
                        cashlessChild: 0,
                        cashlessSenior: 0,
                        cashlessOku: 0,
                        cashlessFAdult: 0,
                        cashlessFChild: 0,
                        noOfAdult: 0,
                        noOfChild: 0,
                        noOfSenior: 0,
                        noOfOku: 0,
                        trxsTime: []
                    }

                    sameTripTrxs.forEach(({
                        userId,
                        amount,
                        noOfAdult,
                        noOfChild,
                        noOfSenior,
                        noOfOku,
                        noOfForeignAdult,
                        noOfForeignChild,
                        journeyCreated,
                        journeyEnded
                    }) => {
                        const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild
                        totalByTrip.routeId = sameTripTrxs[0].routeShortName
                        totalByTrip.routeName = sameTripTrxs[0].routeName
                        totalByTrip.tripId = sameTripTrxs[0].tripId
                        totalByTrip.tripStart = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '-'

                        totalByTrip.tripEnd = moment(sameTripTrxs[0].endedAt).isValid() ? moment(sameTripTrxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '-'
                        totalByTrip.serviceStart = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD-MM-YYYY HH:mm:ss (ddd)') : '00:00'

                        totalByTrip.status = sameTripTrxs[0].endedAt != null ? 'COMPLETE' : 'NOT COMPLETE'
                        totalByTrip.busPlate = sameTripTrxs[0]?.vehicleRegistrationNumber ? sameTripTrxs[0]?.vehicleRegistrationNumber : 'N/A'
                        totalByTrip.driverIdentification = sameTripTrxs[0].driverIdentificationNumber
                        totalByTrip.direction = sameTripTrxs[0].obIb == 1 ? 'OB' : sameTripTrxs[0].obIb == 2 ? 'IB' : 'LOOP'
                        totalByTrip.tripDetail = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD/MM/YYYY HH:mm:ss') : ' '
                        totalByTrip.tripDetailShort = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD/MM/YYYY HH:mm') : ' '
                        totalByTrip.tripDetailDate = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('DD/MM/YYYY') : ' '

                        totalByTrip.noOfAdult += (Number(noOfAdult) + Number(noOfForeignAdult))
                        totalByTrip.noOfChild += (Number(noOfChild) + Number(noOfForeignChild))
                        totalByTrip.noOfSenior += Number(noOfSenior)
                        totalByTrip.noOfOku += Number(noOfOku)
                        totalByTrip.cash += userId ? 0 : amount
                        totalByTrip.cashPax += userId ? 0 : totalPax
                        totalByTrip.cashless += userId ? amount : 0
                        totalByTrip.totalAmount += amount
                        totalByTrip.cashlessPax += userId ? totalPax : 0
                        totalByTrip.cashAdult += userId ? 0 : noOfAdult
                        totalByTrip.cashChild += userId ? 0 : noOfChild
                        totalByTrip.cashSenior += userId ? 0 : noOfSenior
                        totalByTrip.cashOku += userId ? 0 : noOfOku
                        totalByTrip.cashFAdult += userId ? 0 : noOfForeignAdult
                        totalByTrip.cashFChild += userId ? 0 : noOfForeignChild
                        totalByTrip.cashlessAdult += userId ? noOfAdult : 0
                        totalByTrip.cashlessChild += userId ? noOfChild : 0
                        totalByTrip.cashlessSenior += userId ? noOfSenior : 0
                        totalByTrip.cashlessOku += userId ? noOfOku : 0
                        totalByTrip.cashlessFAdult += userId ? noOfForeignAdult : 0
                        totalByTrip.cashlessFChild += userId ? noOfForeignChild : 0

                        totalByTrip.trxsTime.push(userId ? moment(journeyCreated).format('X') : moment(journeyEnded).format('X'))
                        totalByTrip.busAge = sameTripTrxs[0]?.VehicleAge ? moment().year() - sameTripTrxs[0]?.VehicleAge : 'N/A'
                        totalByTrip.kmRate = sameTripTrxs[0]?.kmRate

                        totalByTrip.totalClaim = 0
                        totalByTrip.monthlyPass = '-'
                        totalByTrip.jkm = '-'
                        totalByTrip.maim = '-'




                    })

                    const numberArray = totalByTrip.trxsTime.map(Number);
                    totall = totall + totalByTrip.noOfAdult
                    totallC = totallC + totalByTrip.noOfChild
                    totallS = totallS + totalByTrip.noOfSenior
                    totallO = totallO + totalByTrip.noOfOku
                    day = totalByTrip.tripDetailDate
                    routeN = totalByTrip.routeName
                    routeSN = totalByTrip.routeId
                    routeNGlobal = totalByTrip.routeName
                    routeSNGlobal = totalByTrip.routeId
                    totalByTrip.salesStart = isNaN(moment.unix(Math.min(...numberArray))) ? '-' : moment.unix(Math.min(...numberArray)).format('DD-MM-YYYY HH:mm:ss (ddd)');
                    totalByTrip.salesEnd = isNaN(moment.unix(Math.max(...numberArray))) ? '-' : moment.unix(Math.max(...numberArray)).format('DD-MM-YYYY HH:mm:ss (ddd)');

                    if (prevScheduleTime != totalByTrip.tripDetailShort) {
                        if (!PrevIsAnyTripComplete && indexf !== 0) {
                            tripMissedCounter++
                            tripMissedDateCounter++
                            currentRouteTripCounter++
                            if (isAnyRecord == false) {
                                data += `\r\n`
                                data += header
                                isAnyRecord = true
                            }

                            data += `${prevRouteId},${prevRouteSN} ${prevRouteName},${prevTripDetail},${prevIndex},${prevBusPlate},${prevBusAge},${prevKmRate},\r\n`
                        }

                        PrevIsAnyTripComplete = isComplete

                    }

                    PrevIsAnyTripComplete = isComplete ? isComplete : PrevIsAnyTripComplete
                    if (indexf == uniqueTripsOrderedDirectionRoute.length - 1 && !PrevIsAnyTripComplete) {

                        if (isAnyRecord == false) {
                            data += `\r\n`
                            data += header
                            isAnyRecord = true
                        }
                        tripMissedCounter++
                        tripMissedDateCounter++
                        currentRouteTripCounter++
                        data += `${totalByTrip.routeId},${routeSN} ${sameTripTrxs[0].routeName},${totalByTrip.tripDetail},T${index},${totalByTrip.busPlate},${totalByTrip.busAge},${totalByTrip.kmRate},\r\n`
                    }
                    prevRouteId = totalByTrip.routeId
                    prevRouteSN = routeSN
                    prevRouteName = `${sameTripTrxs[0].routeName}`
                    prevTripDetail = totalByTrip.tripDetail
                    prevIndex = `T${index}`
                    prevBusPlate = totalByTrip.busPlate
                    prevBusAge = totalByTrip.busAge
                    prevKmRate = totalByTrip.kmRate
                    prevScheduleTime = totalByTrip.tripDetailShort
                    if (indexf == uniqueTripsOrderedDirectionRoute.length - 1 && isAnyRecord && indexTop != tabulated.length - 1) {
                        data += `,Total Trip Missed For (${day} - ${routeSN} ${routeN}) ,,,,,${tripMissedCounter},\r\n`

                        grandTotal += tripMissedCounter
                    }
                    if (indexf == uniqueTripsOrderedDirectionRoute.length - 1 && isAnyRecord && indexTop == tabulated.length - 1) {
                        data += `,Total Trip Missed For (${day} - ${routeSN} ${routeN}) ,,,,,${tripMissedCounter},\r\n`

                        grandTotal += tripMissedCounter
                    }
                })
            })
            if (tripMissedDateCounter > 0) {

                data += `,Total Trip Missed For Date : ${day} ,,,,,${tripMissedDateCounter},\r`
            }
            if (indexTop == tabulated.length - 1) {
                if (currentRouteTripCounter > 0) {

                    data += `,Total Trip Missed For ${routeSNGlobal} ${routeNGlobal} : ,,,,,${currentRouteTripCounter},\r`
                }
                currentRouteTripCounter = 0
            }
            currentRoute = trxs[0].routeId

        })
        data += `,Grand Total Trip Missed : ,,,,,${grandTotal},\r\n\n`


        var BOM = "\uFEFF";
        var csvContent = BOM + data;
        exportData(csvContent, `Missed Trip Report-${moment(fromDate, "DD-MM-YYYY").format('MMMYYYY')}-${user.agency.name}.csv`, 'text/csv;charset=utf-8;')

    }, [tabulated])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Trip Missed Report</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        <div>
                            {
                                routesArr ?
                                    <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} agencyNameOpt={[user?.agency?.name]} user={user} />
                                    :
                                    <div className='d-flex justify-content-center align-items-center' >
                                        <CircularProgress />
                                    </div>
                            }
                            <Alert className='mt-3' variant='info' > <InfoCircle /> Trip Missed Report only shows data for the past 1 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                            <div className='w-100 text-right' >
                                <Button onClick={generateExportCsvData} >Export Data</Button>
                            </div>
                            <Table numbering columns={tableHeader} data={claimData} />
                        </div>

                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default TripMissedReportPage

